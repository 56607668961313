export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'ren-atlantic',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.344.9',
	MODULES: [
		'atex-inspections',
		'asset-planning',
		'gap-analysis',
		'inspections',
		'observations',
		'repairs'
	],
	TIMESTAMP: '2024-09-24T20:12:22.020Z',
	COMMIT: 'cc88daa1e10865884c61f743daa07e1867de84dc',
	BRANCH: 'master',
	API_SERVER: 'https://ren-atlantic-api.unoplatform.io:8080',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://ren-atlantic-api.unoplatform.io:8082',
	FILE_CONVERTER_SERVER: 'https://ren-atlantic-api.unoplatform.io:8081',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
